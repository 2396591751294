import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './AddressAndContactInformation.module.css';
import { useTranslation } from 'react-i18next';

AddressAndContactInformation.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    telephone: PropTypes.string.isRequired,
    fax: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};
AddressAndContactInformation.defaultProps = {};

function AddressAndContactInformation({ data }) {
  const { t } = useTranslation();

  const { name, city, postcode, street, telephone, fax, email } = data;
  return (
    <div className={styles.AddressAndContactInformation}>
      <div className={styles.name}>{name}</div>
      <div>
        {street}
        <br />
        {postcode} {city}
        <br />
        {t('austria')}
      </div>
      <div>
        <div>
          {t('telephone_short')} <a href={`tel:${telephone}`}>{telephone}</a>
        </div>
        <div>
          {t('fax')} <a href={`tel:${fax}`}>{fax}</a>
        </div>
        <div>
          {t('fax')} <a href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
    </div>
  );
}

export default AddressAndContactInformation;
