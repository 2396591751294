import React from 'react';
import PropTypes from 'prop-types';
import { oneLineTrim } from 'common-tags';

import * as styles from './StaticMap.module.css';
import { useTranslation } from 'react-i18next';

StaticMap.propTypes = {
  data: PropTypes.shape({
    mapLink: PropTypes.string.isRequired,
    gisCoordiantes: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }).isRequired,
};

function StaticMap({ data }) {
  const { gisCoordinates, mapLink } = data;

  const staticMapUrl = oneLineTrim`
    https://maps.googleapis.com/maps/api/staticmap?
    center=${gisCoordinates.lat + 0.2},${gisCoordinates.lng - 0.2}
    &zoom=8
    &size=700x600
    &maptype=roadmap
    &scale=2
    &markers=anchor:center|${gisCoordinates.lat},${gisCoordinates.lng}
    &key=AIzaSyAKO-D6amXQquS3ymZEF4qoAKF_Y9eEwNs
  `;

  const { t } = useTranslation();
  return (
    <section className={styles.StaticMap}>
      <a className={styles.map} href={mapLink} target="_blank" rel="noreferrer noopener">
        <img src={staticMapUrl} alt={t('map_alt_text')} />
      </a>
    </section>
  );
}

export default StaticMap;
