const React = require('react');

export const clean = (value) => {
  if (isArray(value)) {
    return traverseArray(value);
  }
  if (typeof value === 'object' && value !== null) {
    // filter out images
    if (value && value.publicURL && value.publicURL.indexOf('/static/1200-') >= 0) {
      return null;
    }
    return traverseObject(value);
  }
  return ['_cockpitisnotset_', '_cockpit-field-empty_'].includes(value) ? null : value;
};

const traverseArray = (arr) => arr.map((x) => clean(x)).filter((value) => value != null);

function traverseObject(obj) {
  if (typeof obj._isset !== 'undefined' && !obj._isset) {
    return null;
  }
  const newObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = clean(obj[key]);
    }
  }
  return newObj;
}

function isArray(o) {
  return Object.prototype.toString.call(o) === '[object Array]';
}

const cleanCockpitData = (BaseComponent) => (baseProps) => {
  const transformedProps = {
    ...baseProps,
    data: clean(baseProps.data),
  };
  return <BaseComponent {...transformedProps} />;
};

export default cleanCockpitData;
