import { graphql } from 'gatsby';
import React from 'react';
import clsx from 'clsx';

import Metadata from '../components/Metadata';
import AddressAndContactInformation from '../components/AddressAndContactInformation2';
import Ansprechpartner from '../components/Ansprechpartner';
import StaticMap from '../components/StaticMap';
import KontaktDatenQuery from '../queries/Kontaktdaten';
import MapDataQuery from '../queries/MapLocation';
import cleanCockpitData from '../utils/cleanCockpitData';
import DefaultLayout from '../layout/Default';
import HeaderImage from '../blocks/HeaderImage';

import * as styles from './kontakt.module.css';
import { I18nextContext } from 'gatsby-plugin-react-i18next';

const KontaktPage = ({ data, location }) => {
  const { siteData, pageData, contacts } = data;
  const { title: siteTitle, canonicalSiteUrl } = siteData.siteMetadata;
  const { content, header, metadata, opengraph } = pageData;

  const { title, text, pageID } = content;

  const contactsAsArray = contacts.edges.map(({ node: contact }) => contact);

  const context = React.useContext(I18nextContext);

  return (
    <DefaultLayout>
      <HeaderImage data={header} minHeight={150} />
      <div className={clsx(styles.Kontakt, 'defaultWidth')} id={pageID}>
        <div>
          <h2>{title}</h2>
          <div className="textPage" dangerouslySetInnerHTML={{ __html: text }} />
          <div className={styles.twoColumns}>
            <div>
              <KontaktDatenQuery render={(contactData) => <AddressAndContactInformation data={contactData} />} />
            </div>
            <div>
              <MapDataQuery render={(mapData) => <StaticMap data={mapData} />} />
            </div>
          </div>
          <Ansprechpartner data={contactsAsArray} />
        </div>
      </div>
      <Metadata
        data={metadata}
        opengraph={opengraph}
        defaultTitle={`${title} : ${siteTitle}`}
        canonicalSiteUrl={canonicalSiteUrl}
        path={location.pathname}
      />
    </DefaultLayout>
  );
};

export default cleanCockpitData(KontaktPage);

export const KontaktPageQuery = graphql`
  query KontaktPageQuery($language: String) {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    pageData: cockpitPageContact(lang: { eq: $language }) {
      id
      lang
      content {
        title
        pageID
        text
      }

      header {
        id
        line1
        line2
        textPosition
        published
        linkTarget
        linkLabel
        madeInAustriaOverlay
        image {
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1600, placeholder: NONE)
          }
        }
        mobileImage {
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 900, placeholder: NONE)
          }
        }
      }

      metadata {
        title
        metakeywords
        metadescription
      }

      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              src
              width
              height
              aspectRatio
              originalName
            }
          }
        }
      }
    }

    contacts: allCockpitContacts(filter: { published: { eq: true }, lang: { eq: $language } }) {
      edges {
        node {
          id
          lang
          name
          jobTitle
          telephone
          email
          image {
            id
            childImageSharp {
              fluid(maxWidth: 150) {
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
  }
`;
