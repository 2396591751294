import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { clean } from '../utils/cleanCockpitData';

MapLocationQuery.propTypes = {
  render: PropTypes.func.isRequired,
};
MapLocationQuery.defaultProps = {};

function MapLocationQuery({ render }) {
  const { i18n } = useTranslation();

  return (
    <StaticQuery
      query={graphql`
        query {
          contactData_de: cockpitRegionKontaktdaten(lang: { eq: "de" }) {
            mapLink
            gisCoordinates {
              lat
              lng
              address
            }
          }
          contactData_en: cockpitRegionKontaktdaten(lang: { eq: "en" }) {
            mapLink
            gisCoordinates {
              lat
              lng
              address
            }
          }
        }
      `}
      render={(data) => render(clean(data[`contactData_${i18n.language}`]))}
    />
  );
}

export default MapLocationQuery;
